
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const favoriteapiSlice = createApi({
    reducerPath: 'favoriteapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST', 'PUT', 'DELETE'],
    endpoints: (builder) => ({
        favUser: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.favuserApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
    }),
});

export const { useFavUserMutation } = favoriteapiSlice;