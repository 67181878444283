
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const postapiSlice = createApi({
    reducerPath: 'postapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST', 'PUT', 'DELETE'],
    endpoints: (builder) => ({
        // addPostImage: builder.mutation({
        //     query: (payload) => {
        //         return ({
        //             url: Api.post + Api.addpostImageApi,
        //             method: 'POST',
        //             body: payload,
        //             headers: {
        //                 'Content-type': 'application/json; charset=UTF-8',
        //             },
        //         })
        //     },
        //     invalidatesTags: ['POST']
        // }),
        // getPost: builder.query({
        //     query: ({ id }) => Api.getpostApi + '?id=' + id,
        // }),
        addPost: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.addpostApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        savePost: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.savePost,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        getPosts: builder.query({
            query: ({ offset, type }) => Api.getpostsApi + '?offset=' + offset + '&type=' + type,//id, '?id=' + id + &
        }),
        likePost: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.likepostApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        addCmtPost: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.addcmtpostApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        getCmtPost: builder.query({
            query: ({ id, postId }) => Api.getcmtpostApi + '?id=' + id + '&postId=' + postId,
        }),
        getPost: builder.query({
            query: ({ slug, id }) => Api.getpost + '?slug=' + slug + '&id=' + id,
        }),
    }),
});

export const { useAddPostMutation, useSavePostMutation, useLazyGetPostsQuery, useLikePostMutation,
    useAddCmtPostMutation, useLazyGetCmtPostQuery, useLazyGetPostQuery } = postapiSlice;