
import { createSlice } from "@reduxjs/toolkit";

interface ProfileState {
    userinfo: {
        id: any,
        mobile: any,
        dob: any,
        age: any,
        gender: any,
        address: any,
        locality: any,
        pin: any,
        country: any,
        state: any,
        city: any,
        grade: any,
        board: any,
        subject: any,
        language: any,
        education: any,
        academic_exp: any,
        skill: any,
        service: any,
        profile_prog: any,
        profile_url: any,
        cover_url: any,
    },
    profileInfo: {
        id: any,
        mobile: any,
        dob: any,
        age: any,
        gender: any,
        address: any,
        locality: any,
        pin: any,
        country: any,
        state: any,
        city: any,
        grade: any,
        board: any,
        subject: any,
        language: any,
        education: any,
        academic_exp: any,
        skill: any,
        service: any,
        profile_prog: any,
        profile_url: any,
        cover_url: any,
    },
    profileError: any,
};

const initialState: ProfileState = {
    userinfo: {
        id: null,
        mobile: null,
        dob: null,
        age: null,
        gender: null,
        address: null,
        locality: null,
        pin: null,
        country: null,
        state: null,
        city: null,
        grade: null,
        board: null,
        subject: null,
        language: null,
        education: null,
        academic_exp: null,
        skill: null,
        service: null,
        profile_prog: null,
        profile_url: null,
        cover_url: null,
    },
    profileInfo: {
        id: '',
        mobile: '',
        dob: '',
        age: '',
        gender: '',
        address: '',
        locality: '',
        pin: '',
        country: '',
        state: '',
        city: '',
        grade: '',
        board: '',
        subject: '',
        language: '',
        education: '',
        academic_exp: '',
        skill: '',
        service: '',
        profile_prog: '',
        profile_url: '',
        cover_url: '',
    },
    profileError: '',
};

export const profileSlice = createSlice({
    name: "profile",
    initialState: initialState,
    reducers: {
        /* 
        ~ Imp.: userinfo global state & its functions saveuserinfo & clearuserinfo is used globally in app 
        ~ used for accessing userinfo table's data. So handle with care.
        */
        saveuserinfo: (state: any, userinfo) => {
            return {
                ...state,
                userinfo: { ...state.userinfo, ...userinfo.payload }
            }
        },
        clearuserinfo: (state: any) => {
            return {
                ...state,
                userinfo: {
                    id: null,
                    mobile: null,
                    dob: null,
                    age: null,
                    gender: null,
                    address: null,
                    locality: null,
                    pin: null,
                    country: null,
                    state: null,
                    city: null,
                    grade: null,
                    board: null,
                    subject: null,
                    language: null,
                    education: null,
                    academic_exp: null,
                    skill: null,
                    service: null,
                    profile_prog: null,
                    profile_url: null,
                    cover_url: null,
                },
            }
        },
        /* This below saveprofileSuccess uses immutability that means it doesn't remove previous state
        ^ This below saveProfileSuccess & clearProfile states are made for completing profile(userinfo table) 
        ^ from homepage for new user who has profile_prog as 0. This profileInfo global state is not used 
        ^ anywhere else apart from prfcplt modals */
        saveprofileSuccess: (state: any, profileInfo) => {
            return {
                ...state,
                profileInfo: { ...state.profileInfo, ...profileInfo.payload }
            }
        },
        saveprofileError: (state: any, { payload }) => {
            state.profileError = payload
        },
        clearProfile: (state: any) => {
            return {
                userinfo: {
                    id: null,
                    mobile: null,
                    dob: null,
                    age: null,
                    gender: null,
                    address: null,
                    locality: null,
                    pin: null,
                    country: null,
                    state: null,
                    city: null,
                    grade: null,
                    board: null,
                    subject: null,
                    language: null,
                    education: null,
                    academic_exp: null,
                    skill: null,
                    service: null,
                    profile_prog: null,
                    profile_url: null,
                    cover_url: null,
                },
                profileInfo: {
                    id: '',
                    mobile: '',
                    dob: '',
                    age: '',
                    gender: '',
                    address: '',
                    locality: '',
                    pin: '',
                    country: '',
                    state: '',
                    city: '',
                    grade: '',
                    board: '',
                    subject: '',
                    language: '',
                    education: '',
                    academic_exp: '',
                    skill: '',
                    service: '',
                    profile_prog: '',
                    profile_url: '',
                    cover_url: '',
                },
                profileError: '',
            }
        }
    },
}
);

export const {
    saveuserinfo,
    clearuserinfo,
    saveprofileSuccess,
    saveprofileError,
    clearProfile,
} = profileSlice.actions;

export default profileSlice.reducer;