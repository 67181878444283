
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const completeprofileapiSlice = createApi({
    reducerPath: 'completeprofileapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.postURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        addProfile: builder.mutation({
            query: ({ id, mobile, dob, age, gender, address, locality, pin, country, state, city, grade, board, subject, language, education, academic_exp, skill, service, profile_prog }) => {
                let payload = {
                    id: id,
                    mobile: mobile,
                    dob: dob,
                    age: age,
                    gender: gender,
                    address: address,
                    locality: locality,
                    pin: pin,
                    country: country,
                    state: state,
                    city: city,
                    grade: grade,
                    board: board,
                    subject: subject,
                    language: language,
                    education: education,
                    academic_exp: academic_exp,
                    skill: skill,
                    service: service,
                    profile_prog: profile_prog,
                };

                return ({
                    url: Api.completeprofileApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['Post']
        }),
    }),
});

export const { useAddProfileMutation } = completeprofileapiSlice;