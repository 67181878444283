
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const jobapiSlice = createApi({
    reducerPath: 'jobapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST', 'PUT', 'DELETE'],
    endpoints: (builder) => ({
        addJob: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.addjobApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        addJobMail: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.addjobmailApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        getJob: builder.query({
            query: ({ id, pageId }) => Api.getjobApi + '?id=' + id + '&pageId=' + pageId,
        }),
        getPublicJob: builder.query({
            query: ({ jobslug }) => Api.getpubjobApi + '?jobslug=' + jobslug,
        }),
        applyJob: builder.mutation({
            query: (formData) => {
                return ({
                    url: Api.post + Api.applyjobApi,
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Accept': 'multipart/form-data',
                        id: formData.get('id'),
                        path: formData.get('path')
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        applyJobMail: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.post + Api.applyjobmailApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        getJobApplicant: builder.query({
            query: ({ pageId, jobId }) => Api.getjobapplicantApi + '?pageId=' + pageId + '&jobId=' + jobId,
        }),
        getViewJob: builder.query({
            query: ({ id, jobId }) => Api.getviewjobApi + '?id=' + id + '&jobId=' + jobId,
        }),
        getMyFreshJob: builder.query({
            query: ({ id }) => Api.getfreshjobApi + '?id=' + id,
        }),
        getMyAppliedJob: builder.query({
            query: ({ id }) => Api.getappliedjobApi + '?id=' + id,
        }),
        getMyFilterJob: builder.query({
            query: ({ id, jobRole, jobLocation }) => Api.getfilterjobApi + '?id=' + id + '&jobRole=' + jobRole + '&jobLocation=' + jobLocation,
        }),
        editJobClose: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editjobcloseApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        getMyPostedJob: builder.query({
            query: ({ id }) => Api.getpostedjobApi + '?id=' + id,
        }),
        getMyClosedJob: builder.query({
            query: ({ id }) => Api.getclosedjobApi + '?id=' + id,
        }),
        editApplicantStatus: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editapplicantstsApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
    }),
});

export const { useAddJobMutation, useAddJobMailMutation, useLazyGetJobQuery, useLazyGetPublicJobQuery,
    useApplyJobMutation, useApplyJobMailMutation, useLazyGetJobApplicantQuery,
    useLazyGetViewJobQuery, useLazyGetMyFreshJobQuery, useLazyGetMyAppliedJobQuery,
    useLazyGetMyFilterJobQuery, useEditJobCloseMutation, useLazyGetMyPostedJobQuery,
    useLazyGetMyClosedJobQuery, useEditApplicantStatusMutation } = jobapiSlice;