
import { ThreeDots } from 'react-loader-spinner';
import { usePromiseTracker } from 'react-promise-tracker';
const LoadingIndicator = () => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress &&
        <div
            style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(0px, -50%)",
            }}>
            <ThreeDots type="ThreeDots" color="#2BAD60" height="100" width="100" />
        </div>
};

export default LoadingIndicator;