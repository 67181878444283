
import env from '../../env.json';

const url = `${env.BASE_URL}`;//:${env.PORT}
export const client_dev_url = 'http://dev.easyedulink.com/';
export const rootURL = `${url}`;
export const baseURL = `${url}/api/v1/`;
export const postURL = `${url}/api/v1/post/`;
export const putURL = `${url}/api/v1/put/`;
export const deleteURL = `${url}/api/v1/delete/`;

// Request types
export const post = 'post/';
export const put = 'put/';
export const del = 'delete/';

// Endpoints
export const getallregisterApi = 'getallregister';
export const loginApi = 'login';
export const registerApi = 'register';
export const completeprofileApi = 'completeprofile';
export const getregisterbyemailApi = 'getregisterbyemail';
export const fetchuserinfoApi = 'fetchuserinfo';
export const editbasicApi = 'editbasic';
export const editteachApi = 'editteach';
export const editexpApi = 'editexp';
export const editeduApi = 'editedu';
export const editskillApi = 'editskill';
export const edituserApi = 'editname';
export const editavailApi = 'editavail';
export const editcoverApi = 'editcover';
export const editdpApi = 'editdp';
export const createeventApi = 'createevent';
//export const fetcheventApi = 'fetchevent';
export const getMyEvents = 'getMyEvents';
export const publisheventApi = 'publishevent';
export const unpublisheventApi = 'unpublishevent';
export const editeventApi = 'editevent';
export const individualeventApi = 'event/';
export const fetchalleventApi = 'fetchallevent';
export const createpageApi = 'createpage';
export const fetchpageApi = 'fetchpage';
export const fetchmypageApi = 'fetchmypage';
export const editpageApi = 'editpage';
export const delpageApi = 'delpage';
export const getteacherApi = 'getteacher';
export const getstudentApi = 'getstudent';
export const forgetpwdApi = 'forgetpwd';
export const publicprofileApi = 'pubprofile';
export const addpostApi = 'addpost';
export const savePost = 'savePost';
// export const addpostImageApi = 'upload/postimage';
export const getpostsApi = 'getposts';
export const likepostApi = 'likepost';
export const addcmtpostApi = 'addcmtpost';
export const getcmtpostApi = 'getcmtpost';
export const getfaviconmetaApi = 'getfaviconmeta';
export const addjobApi = 'addjob';
export const getjobApi = 'getjob';
export const getpubjobApi = 'getpubjob';
export const applyjobApi = 'applyjob';
export const getjobapplicantApi = 'getjobapplicant';
export const addjobmailApi = 'addjobmail';
export const applyjobmailApi = 'applyjobmail';
export const getviewjobApi = 'getviewjob';
export const getfreshjobApi = 'getfreshjob';
export const getappliedjobApi = 'getappliedjob';
export const getfilterjobApi = 'getfilterjob';
export const editjobcloseApi = 'editjobclose';
export const getpostedjobApi = 'getpostedjob';
export const getclosedjobApi = 'getclosedjob';
export const editapplicantstsApi = 'editapplicantsts';
export const favuserApi = 'favuser';
export const searchApi = 'search';
export const getteachers = 'getteachers';
export const getpost = 'getpost';
export const getfollowers = 'getfollowers';
export const getfollowing = 'getfollowing';
export const addchat = 'addchat';
export const getchat = 'getchat';
export const getuser = 'getuser';
export const getChatsUsers = 'getChatsUsers';
export const adminlogin = 'adminlogin';
export const getjobs = 'getjobs';
export const jobactivate = 'jobactivate';
export const addjoinevent = 'addjoinevent';
export const isEventUrl = 'isEventUrl';
export const getEventJoinees = 'getEventJoinees';
export const editEventImg = 'editEventImg';
export const getPostRaw = 'getPostRaw';
export const getMyJoinedEvents = 'getMyJoinedEvents';
export const getPublicEvents = 'getPublicEvents';
export const refreshToken = 'refreshToken';
export const getUsersAccRole = 'getUsersAccRole';
export const editLastSeen = 'editLastSeen';
export const getZoomJWT = 'getZoomJWT';
export const addZoomMeeting = 'addZoomMeeting';
export const getZakToken = 'getZakToken';
export const users = 'users'