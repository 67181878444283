
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const profileapiSlice = createApi({
    reducerPath: 'profileapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST', 'PUT', 'DELETE'],
    endpoints: (builder) => ({
        fetchuserInfo: builder.query({
            query: () => Api.fetchuserinfoApi,
        }),
        getPublicprofile: builder.query({
            query: ({ profilepath, id }) => Api.publicprofileApi + "/" + profilepath + '?id=' + id,
        }),
        editCover: builder.mutation({
            query: (formData) => {
                return ({
                    url: Api.put + Api.editcoverApi,
                    method: 'PUT',
                    body: formData,
                    headers: {
                        'Accept': 'multipart/form-data',
                        id: formData.get('id'),
                        path: formData.get('path')
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editDp: builder.mutation({
            query: (formData) => {
                return ({
                    url: Api.put + Api.editdpApi,
                    method: 'PUT',
                    body: formData,
                    headers: {
                        'Accept': 'multipart/form-data',
                        id: formData.get('id'),
                        path: formData.get('path')
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editBasic: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editbasicApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editTeach: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editteachApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editExp: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editexpApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editEdu: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editeduApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editSkill: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editskillApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editUser: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.edituserApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        editAvailability: builder.mutation({
            query: (payload) => {
                return ({
                    url: Api.put + Api.editavailApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
    })
});

export const { useLazyFetchuserInfoQuery, useLazyGetPublicprofileQuery, useEditCoverMutation, useEditDpMutation,
    useEditBasicMutation, useEditUserMutation, useEditTeachMutation, useEditExpMutation, useEditEduMutation,
    useEditSkillMutation, useEditAvailabilityMutation } = profileapiSlice;