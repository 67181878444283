
import { Navigate } from "react-router-dom";
import * as customLocalStorage from '../../helpers/customLocalStorage';

const ProtectedRoute = ({ user, children }: any) => {
    // session will be used for a user login kind of preferences save(needed to open multiple tabs so migrated to local storage[with expiry that's why custom])    
    if (customLocalStorage.getItem("isAuthenticated")) {
        return children;
    }
    return <Navigate to="/login" replace />;

};

export default ProtectedRoute;