
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const getregisterapiSlice = createApi({
    reducerPath: 'getregisterapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['Get'],
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => Api.getallregisterApi,
        }),
    }),
});

// Add Lazy after "use" to convert it into Lazy Query hook, use with get requests
export const { useLazyGetUsersQuery } = getregisterapiSlice