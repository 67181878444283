
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const getregisteremailapiSlice = createApi({
    reducerPath: 'getregisteremailapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.postURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['Post'],
    endpoints: (builder) => ({
        getUserByEmail: builder.mutation({
            query: ({ email }) => {
                let payload = {
                    email: email,
                };

                return ({
                    url: Api.getregisterbyemailApi,
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['Post']
        }),
    }),
});

export const { useGetUserByEmailMutation } = getregisteremailapiSlice;