
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const eventapiSlice = createApi({
    reducerPath: 'eventapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST', 'PUT', 'DELETE'],
    endpoints: (builder) => ({
        addEvent: builder.mutation({
            query: (formData) => {
                return ({
                    url: Api.post + Api.createeventApi,
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Accept': 'multipart/form-data',
                        id: formData.get('id'),
                        path: formData.get('path')
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        editEvent: builder.mutation({
            query: (payload) => {//formData
                return ({
                    url: Api.put + Api.editeventApi,
                    method: 'PUT',
                    body: payload,//formData,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        /*'Accept': 'multipart/form-data',
                        id: formData.get('id'),
                        path: formData.get('path')*/
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        deleteEvent: builder.mutation({
            query: ({ event_id }) => {
                return ({
                    url: Api.del + Api.individualeventApi + event_id,
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['DELETE']
        }),
        fetchAllEvent: builder.query({
            query: ({ offset }) => Api.fetchalleventApi + '?offset=' + offset,//id,  '?id=' + id + &
        }),
        fetchEvent: builder.query({
            query: ({ id, slug }) => Api.individualeventApi + slug + '?id=' + id,
        }),
        /*fetchEvents: builder.mutation({
            query: ({ id }) => {
                let payload = {
                    id: id,
                };

                return ({
                    url: Api.post + Api.fetcheventApi,
                    method: 'POST',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),*/
        publishEvent: builder.mutation({
            query: ({ event_id }) => {
                let payload = {
                    event_id: event_id,
                };

                return ({
                    url: Api.put + Api.publisheventApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        unpublishEvent: builder.mutation({
            query: ({ event_id }) => {
                let payload = {
                    event_id: event_id,
                };

                return ({
                    url: Api.put + Api.unpublisheventApi,
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
    }),
});

export const { useAddEventMutation, useEditEventMutation, useDeleteEventMutation,
    useLazyFetchAllEventQuery, useLazyFetchEventQuery, //useFetchEventsMutation,
    usePublishEventMutation, useUnpublishEventMutation } = eventapiSlice;