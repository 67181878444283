import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './features/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
//import { ProSidebarProvider } from "react-pro-sidebar";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingIndicator from './components/Loader/LoadingIndicator';
let persistor = persistStore(store); // This persistor & PersistGate is necessary for redux state persist
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId='354725937648-qjn7c9oqhu09doe1hg5s7aqlf4h2f97p.apps.googleusercontent.com'>
    <Provider store={store} >
      <React.StrictMode>
        {/*<ProSidebarProvider>*/}
          <PersistGate persistor={persistor}>
            <App />
            <LoadingIndicator />
          </PersistGate>
        {/*</ProSidebarProvider>*/}
      </React.StrictMode>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();