
import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import ProtectedRoute from './components/Auth/ProtectedRoute'
import AdminProtected from './components/Auth/AdminProtected'
import { HelmetProvider } from 'react-helmet-async';
import AuthProvider from "./provider/authProvider";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Pages
const Landing = React.lazy(() => import('./views/pages/landing/LandingPage'));
const Educator = React.lazy(() => import('./views/pages/educator/educator'));
const Login = React.lazy(() => import('./views/pages/login/LoginPage'));
const Register = React.lazy(() => import('./views/pages/register/RegisterPage'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Forgot = React.lazy(() => import('./views/pages/forgot/ResetPassword'));
const PrivacyPolicy = React.lazy(() => import('./views/pages/privacypolicy/privacy_policy'));
const Profile = React.lazy(() => import('./views/pages/profile/profile'));
const Home = React.lazy(() => import('./views/pages/homepage/home'));
const Event = React.lazy(() => import('./views/pages/event/event'));
const EventPage = React.lazy(() => import('./views/pages/eventpage/eventpage'));
const MyEvent = React.lazy(() => import('./views/pages/myevent/myevent'));
const MyEventPage = React.lazy(() => import('./views/pages/myeventpage/myeventpage'));
const Page = React.lazy(() => import('./views/pages/page/page'));
const PageDetail = React.lazy(() => import('./views/pages/pagedetail/pagedetail'));
const MyPage = React.lazy(() => import('./views/pages/mypage/mypage'));
const Users = React.lazy(() => import('./views/pages/users/users'));
//const Teacher = React.lazy(() => import('./views/pages/teacher/teacher'));
//const Student = React.lazy(() => import('./views/pages/student/student'));
const Viewjob = React.lazy(() => import('./views/pages/viewjob/viewjob'));
const Jobapplicant = React.lazy(() => import('./views/pages/jobapplicant/jobapplicant'));
const PostJob = React.lazy(() => import('./views/pages/postjob/postjob'));
const Jobs = React.lazy(() => import('./views/pages/jobs/jobs'));
const Search = React.lazy(() => import('./views/pages/search/search'));
const Post = React.lazy(() => import('./views/pages/post/post'));
const Followers = React.lazy(() => import('./views/pages/followers/followers'));
const Following = React.lazy(() => import('./views/pages/following/following'));
const Chat = React.lazy(() => import('./views/pages/chat/chat'));
const AdminLogin = React.lazy(() => import('./views/pages/admin/admin_login'));
const AdminDashboard = React.lazy(() => import('./views/pages/admin/admin_dashboard'));
const EventLive = React.lazy(() => import('./views/pages/event/event_live'));
const ZoomMeeting = React.lazy(() => import('./views/pages/event/zoom_meeting'));
const JitsiMeet = React.lazy(() => import('./views/pages/event/jitsi_meet'));

const App = () => {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <AuthProvider>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/educator" element={<Educator />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/404" element={<Page404 />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/home" element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>} />
              <Route path="/event" element={
                <ProtectedRoute>
                  <Event />
                </ProtectedRoute>} />
              <Route path="/event/:slug" element={<EventPage />} />
              <Route path="/event/live/:url" element={<EventLive />} />
              <Route path='/event/live/zoom-meet' element={<ZoomMeeting />} />
              <Route path='/event/live/jitsi-meet/:room' element={<JitsiMeet />} />
              <Route path="/myevent" element={
                <ProtectedRoute>
                  <MyEvent />
                </ProtectedRoute>} />
              <Route path="/myevent/:slug" element={
                <ProtectedRoute>
                  <MyEventPage />
                </ProtectedRoute>} />
              <Route path="/page" element={
                <ProtectedRoute>
                  <Page />
                </ProtectedRoute>} />
              <Route path="/page/:pageid" element={
                <ProtectedRoute>
                  <PageDetail />
                </ProtectedRoute>} />
              <Route path="/mypage" element={
                <ProtectedRoute>
                  <MyPage />
                </ProtectedRoute>} />
              <Route path='/users' element={<ProtectedRoute>
                <Users />
              </ProtectedRoute>} />
              {/*<Route path="/teacher" element={
                <ProtectedRoute>
                  <User />
                  {/*<Teacher />}
                </ProtectedRoute>} />
              <Route path="/student" element={
                <ProtectedRoute>
                  <User />
                  {/*<Student />}
                </ProtectedRoute>} />*/}
              <Route path="/job-applications" element={
                <ProtectedRoute>
                  <Jobapplicant />
                </ProtectedRoute>} />
              <Route path="/:profilepath" element={<Profile />} />
              <Route path='/job/:jobslug' element={<Viewjob />} />
              <Route path='/post-job' element={
                <ProtectedRoute>
                  <PostJob />
                </ProtectedRoute>
              } />
              <Route path='/jobs' element={
                <ProtectedRoute>
                  <Jobs />
                </ProtectedRoute>
              } />
              <Route path='/search/results/all/' element={
                <ProtectedRoute>
                  <Search />
                </ProtectedRoute>
              } />
              <Route path='/post/:slug' element={<Post />} />
              <Route path='/followers' element={
                <ProtectedRoute>
                  <Followers />
                </ProtectedRoute>
              } />
              <Route path='/following' element={
                <ProtectedRoute>
                  <Following />
                </ProtectedRoute>
              } />
              <Route path='/chat' element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              } />
              <Route path='/admin' element={<AdminLogin />} />
              <Route path='/admin/dashboard' element={
                <AdminProtected>
                  <AdminDashboard />
                </AdminProtected>
              } />
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    </HelmetProvider>
  )
}

export default App;