
import { createSlice } from "@reduxjs/toolkit";

interface RouteState {
    redirect: any,
};

const initialState: RouteState =
{
    redirect: '',
};

export const routeSlice = createSlice({
    name: 'route',
    initialState: initialState,
    reducers: {
        saveRoute: (state: any, { payload }) => {
            state.redirect = payload;
        },
        clearRoute: (state: any) => {
            state.redirect = '';
        },
    },
});

export const { saveRoute, clearRoute } = routeSlice.actions;

export default routeSlice.reducer;