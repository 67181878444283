
import { createSlice } from "@reduxjs/toolkit";

interface UserState {
  userInfo: {
    user: {
      id: any,
      firstName: any,
      lastName: any,
      qualification: any,
    },
  },
  userinfo: String,
  loginInfo: {
    accessToken: any,
    user: {
      name: any
    }
  },
  token: {},
  isAuthenticated: Boolean,
  error: String,
  loginstatus: String,
};

const initialState: UserState = {
  userInfo: {
    user: {
      id: '',
      firstName: '',
      lastName: '',
      qualification: '',
    }
  },
  userinfo: '',
  loginInfo: {
    accessToken: '',
    user: {
      name: null
    }
  },
  token: {},
  isAuthenticated: false,
  error: "",
  loginstatus: ""
}

export const userSlice: any = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    loginTokenSuccess: (state, { payload }) => {
      state.token = payload
    },
    loginSuccess: (state, { payload }) => {
      state.loginInfo = payload
      state.userInfo.user = payload["user"]
      state.userinfo = payload["userinfo"]
      state.isAuthenticated = true
      state.error = ""
      state.loginstatus = "Success"
    },
    loginError: (state, { payload }) => {
      state.error = payload
      state.isAuthenticated = false
      state.loginstatus = "Failed"
    },
    loginStatus: (state, { payload }) => {
      state.loginstatus = payload
    },
    logoutSuccess: (state, { payload }) => {
      state.loginInfo = {
        accessToken: '',
        user: {
          name: payload
        }
      }
      state.isAuthenticated = false
      state.error = ""
      state.loginstatus = ""
    },
    logoutError: (state, { payload }) => {
      state.error = payload
    },
    registerSuccess: (state, { payload }) => {
      state.userInfo = payload
      state.isAuthenticated = true
    },
    resetState: (state, initialState): any => {
      return {
        userInfo: {
          accessToken: '',
          user: {
            name: null
          }
        },
        loginInfo: {
          user: {
            name: null
          }
        },
        token: {},
        isAuthenticated: false,
        error: "",
        loginstatus: ""
      };
    },
  }
})

export const {
  loginTokenSuccess,
  loginSuccess,
  loginError,
  loginStatus,
  logoutSuccess,
  logoutError,
  registerSuccess,
  resetState,
} = userSlice.actions

export default userSlice.reducer