
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const getapiSlice = createApi({
    reducerPath: 'getapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST', 'PUT', 'DELETE'],
    endpoints: (builder) => ({
        getFaviconMeta: builder.query({
            query: ({ url }) => Api.getfaviconmetaApi + '?url=' + encodeURIComponent(url),
        }),
        search: builder.query({
            query: ({ id, keyword, offset }) => Api.searchApi + '?id=' + id + '&keyword=' + keyword + '&offset=' + offset,
        }),
    }),
});

export const { useLazyGetFaviconMetaQuery, useLazySearchQuery } = getapiSlice;