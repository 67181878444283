
import { combineReducers, configureStore } from "@reduxjs/toolkit"
import userReducer from "./userSlice"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { persistReducer } from "redux-persist"
import { registerapiSlice } from "./api/registerapiSlice"
import { getregisterapiSlice } from "./api/getregisterapiSlice"
import { loginapiSlice } from "./api/loginapiSlice"
import profileReducer from "./profileSlice";
import { completeprofileapiSlice } from "./api/completeprofileapiSlice";
import { getregisteremailapiSlice } from "./api/getregisteremailapiSlice";
//import { fetchuserinfoapiSlice } from "./api/fetchuserinfoapiSlice";
//import storagesession from 'redux-persist/lib/storage/session';
import localStorage from "redux-persist/es/storage";
import { eventapiSlice } from "./api/eventapiSlice";
import { pageapiSlice } from "./api/pageapiSlice";
//import { teacherapiSlice } from "./api/teacherapiSlice";
//import { studentapiSlice } from "./api/studentapiSlice";
import { forgetpwdapiSlice } from "./api/forgetpwdapiSlice";
import { profileapiSlice } from "./api/profileapiSlice";
import { postapiSlice } from "./api/postapiSlice";
import { getapiSlice } from "./api/getapiSlice";
import { jobapiSlice } from "./api/jobapiSlice";
import routeReducer from './routeSlice';
import { favoriteapiSlice } from "./api/favoriteapiSlice";
import { userapi } from "./api/userapi"

const persistConfig = {
  key: "root",
  version: 1,
  storage: AsyncStorage
  // whitelist: ["user", "profile"] (only values which you put array in array will be persisted like here it's user & profile)
}

const userpersistConfig = {
  key: "user", // This is Key object, the value which you want to persist
  storage: localStorage,//storagesession
};

const profilepersistConfig = {
  key: "profile",
  storage: localStorage,//storagesession
};

const routepersistConfig = {
  key: 'route',
  storage: localStorage,
};

const rootuserReducer = combineReducers({
  user: userReducer
})

const rootprofileReducer = combineReducers({
  profile: profileReducer
});

const user = persistReducer(userpersistConfig, rootuserReducer)

const profile = persistReducer(profilepersistConfig, rootprofileReducer);

const route = persistReducer(routepersistConfig, routeReducer);

export const store = configureStore({
  reducer: {
    user: user,
    [loginapiSlice.reducerPath]: loginapiSlice.reducer,
    [getregisterapiSlice.reducerPath]: getregisterapiSlice.reducer,
    [registerapiSlice.reducerPath]: registerapiSlice.reducer,
    [completeprofileapiSlice.reducerPath]: completeprofileapiSlice.reducer,
    [getregisteremailapiSlice.reducerPath]: getregisteremailapiSlice.reducer,
    //[fetchuserinfoapiSlice.reducerPath]: fetchuserinfoapiSlice.reducer,
    [eventapiSlice.reducerPath]: eventapiSlice.reducer,
    [pageapiSlice.reducerPath]: pageapiSlice.reducer,
    //[teacherapiSlice.reducerPath]: teacherapiSlice.reducer,
    //[studentapiSlice.reducerPath]: studentapiSlice.reducer,
    [forgetpwdapiSlice.reducerPath]: forgetpwdapiSlice.reducer,
    [profileapiSlice.reducerPath]: profileapiSlice.reducer,
    [postapiSlice.reducerPath]: postapiSlice.reducer,
    [getapiSlice.reducerPath]: getapiSlice.reducer,
    [jobapiSlice.reducerPath]: jobapiSlice.reducer,
    [favoriteapiSlice.reducerPath]: favoriteapiSlice.reducer,
    [userapi.reducerPath]: userapi.reducer,
    profile: profile,
    route: route,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(loginapiSlice.middleware)
      .concat(getregisterapiSlice.middleware)
      .concat(registerapiSlice.middleware)
      .concat(completeprofileapiSlice.middleware)
      .concat(getregisteremailapiSlice.middleware)
      //.concat(fetchuserinfoapiSlice.middleware)
      .concat(eventapiSlice.middleware)
      .concat(pageapiSlice.middleware)
      //.concat(teacherapiSlice.middleware)
      //.concat(studentapiSlice.middleware)
      .concat(forgetpwdapiSlice.middleware)
      .concat(profileapiSlice.middleware)
      .concat(postapiSlice.middleware)
      .concat(getapiSlice.middleware)
      .concat(jobapiSlice.middleware)
      .concat(favoriteapiSlice.middleware)
      .concat(userapi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;

export default store;