
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const forgetpwdapiSlice = createApi({
    reducerPath: 'forgetpwdapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST'],
    endpoints: (builder) => ({
        forgetPassword: builder.mutation({
            query: (formData) => {
                return ({
                    url: Api.post + Api.forgetpwdApi,
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
    }),
});

export const { useForgetPasswordMutation, } = forgetpwdapiSlice;