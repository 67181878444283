
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const pageapiSlice = createApi({
    reducerPath: 'pageapiSlice',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    tagTypes: ['POST', 'PUT', 'DELETE'],
    endpoints: (builder) => ({
        addPage: builder.mutation({
            query: (formData) => {
                return ({
                    url: Api.post + Api.createpageApi,
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Accept': 'multipart/form-data',
                        id: formData.get('id'),
                        path: formData.get('path')
                    },
                })
            },
            invalidatesTags: ['POST']
        }),
        fetchPage: builder.query({
            query: ({ offset }) => Api.fetchpageApi + '?offset=' + offset,
        }),
        fetchMypage: builder.query({
            query: () => Api.fetchmypageApi,//{ id } + '?id=' + id,
        }),
        fetchPagedetail: builder.query({
            query: ({ pageid }) => Api.fetchpageApi + '/' + pageid,
        }),
        editPage: builder.mutation({
            query: (formData) => {
                return ({
                    url: Api.put + Api.editpageApi,
                    method: 'PUT',
                    body: formData,
                    headers: {
                        'Accept': 'multipart/form-data',
                        id: formData.get('id'),
                        path: formData.get('path')
                    },
                })
            },
            invalidatesTags: ['PUT']
        }),
        deletePage: builder.mutation({
            query: ({ pageid }) => {
                return ({
                    url: Api.del + Api.delpageApi + '/' + pageid,
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
            },
            invalidatesTags: ['DELETE']
        }),
    }),
});

export const { useAddPageMutation, useLazyFetchPageQuery,
    useLazyFetchMypageQuery, useLazyFetchPagedetailQuery,
    useEditPageMutation, useDeletePageMutation } = pageapiSlice;