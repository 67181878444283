import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { resetState } from "../features/userSlice";
import * as Api from '../helpers/constant/api_const';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    // State to hold the authentication token
    const [token, setToken_] = useState(localStorage.getItem("token"));
    const dispatch = useDispatch();

    // Function to set the authentication token
    const setToken = (newToken) => {
        setToken_(newToken);
    };

    const logout = () => {
        dispatch(resetState(null));
        localStorage.clear();
        navigate('/login');
    };

    const refreshToken = async () => {
        try {
            const { data } = await axios.post(Api.postURL + Api.refreshToken, { refreshToken: localStorage.getItem('refreshToken') });
            if (data.status === 200) {
                localStorage.setItem("token", data.data.accessToken);
                //customLocalStorage.setItem("token", data.data.accessToken, 1 * 24 * 60 * 60 * 1000);
            }
        } catch (error) {
            console.error(error);
            logout();
        }
    };

    useEffect(() => {
        console.log('here')
        if (token || localStorage.getItem('refreshToken')) {
            const decodedToken = jwtDecode(token);
            //const decodedtoken = decoded;jwt.decode(token);
            const tokenExp = new Date(decodedToken.exp * 1000);
            const now = new Date();

            // Refresh token if it's about to expire(less than 1 min)
            if (tokenExp - now < 60 * 1000) {
                console.log('Less than 1 min left so refresh');
                refreshToken();
            }
            axios.defaults.headers.common["Authorization"] = token;
            localStorage.setItem("token", token);
        } else {
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem("token");
        }

        /*const interval = setInterval(() => {
            console.log('Automatic 15 min refresh')
            refreshToken();
        }, 15 * 60 * 1000);// Refresh every 15 minutes

        return () => clearInterval(interval);*/
    }, [token]);

    // Memoized value of the authentication context
    const contextValue = useMemo(
        () => ({
            token,
            setToken,
        }),
        [token]
    );

    // Provide the authentication context to the children components
    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;