import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as Api from '../../helpers/constant/api_const';

export const userapi = createApi({
    reducerPath: 'userapi',
    baseQuery: fetchBaseQuery({
        baseUrl: Api.baseURL,
        prepareHeaders: (headers) => {
            headers.set("Authorization", localStorage.getItem('token') || '')
        }
    }),
    endpoints: (builder) => ({
        getUsersAccRole: builder.query({
            query: ({ role, offset }) => Api.getUsersAccRole + '?role=' + role + '&offset=' + offset,
        }),
        getFollowers: builder.query({
            query: ({ id }) => Api.getfollowers + '?id=' + id,
        }),
        getFollowing: builder.query({
            query: ({ id }) => Api.getfollowing + '?id=' + id,
        }),
        getTeachers: builder.query({
            query: (progress) => Api.getteachers + '?progress=' + progress,
        }),
    }),
});

export const {
    useLazyGetUsersAccRoleQuery,
    useLazyGetFollowersQuery,
    useLazyGetFollowingQuery,
    useLazyGetTeachersQuery
} = userapi;